@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

body {
	margin:0 auto;
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.5;
	color: #545454;
}
h1, h2, h3, h4, h5, h6 {
	font-family: "Lato";
	text-transform: uppercase;
	color: rgb(0, 0, 0);
	line-height: 1.2;
	font-weight: 700;
	margin:0 auto 30px;
}
@media only screen and (max-width:768px) {

.inner-wrap {
	padding: 20px;
	margin: 0 auto;
	box-sizing: border-box;
}


.brushbg-pink, .brushbg-black, .brushbg-gray {
	margin-top: -135px;
}
.brushbg-pink img, .brushbg-black img, .brushbg-gray img {
	width:100%;
	height:auto;
}
.separator-1 {
	width: 225px;
	height: 5px;
	margin: 20px auto;
	background: #DB397C;
}
.col-1 {
	width:100%;
	padding:20px;
	box-sizing: border-box;
}
.col-2-1 {
	width:100%;
	padding:20px;
	box-sizing: border-box;
}
.col-2-2 {
	width:100%;
	padding:20px;
	box-sizing: border-box;
}
.row {
	display: block;
	width: 100%;
	box-sizing: border-box;
}
.col-3-1, .col-3-2, .col-3-3 {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}
.container {
	
}
.header {
	text-align: center;
	display: block;
}
.header .inner-wrap {
	background: url(images/herobg1.png)no-repeat scroll center center transparent;
	min-height: 250px;
	padding: 20px 0;
	align-content: center;
	display: grid;
}
.header h1 {
	margin:0 auto;
}
.subtitle h3 span {
	color:#d7387c;
}
.name-search {
	text-align: center;
	background:#dc397d;
	color:fff;
}
.name-search h2 {
	color: #fff;
	display: block;
	padding: 20px 20px;
	z-index: 1;
	position: relative;
	width:100%;
	line-height:1;
}
.name-search .inner-wrap {
	padding: 20px 20px;
	position: relative;
	width: 100%;
}
form.rsearch {
	margin: auto;
	max-width: 300px;
	display: flex;
}
form.rsearch input[type="text"] {
	padding: 10px;
	font-size: 17px;
	border: 1px solid #fff;
	float: left;
	width: 80%;
	background: transparent;
	color: #fff;
}
form.rsearch input[type="text"]:focus {
	border: 1px solid #fff;
}

form.rsearch button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #101E4D;
  color: white;
  font-size: 17px;
  border: 1px solid #779BE7;
  border-left: none;
  cursor: pointer;
  transition:all 0.3s ease-in-out;
}

form.rsearch button:hover {
  background: #779BE7;
  border:1px solid #779BE7;
  transition:all 0.3s ease-in-out;
}

form.rsearch::after {
  content: "";
  clear: both;
  display: table;
}
/*.name-search input {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #fff;
	background: transparent;
	color: #fff;
	font-size: 18px;
	font-weight: normal;
}*/
.age-group {
	background:#fff;
	color:#666;
	text-align: center;
}
.age-group .inner-wrap {
	padding: 20px 20px;
	position: relative;
	width: 100%;
}
.age-group .row {
	padding: 20px 20px;
}
.age-group h2 {
	color: #666;
	display: block;
	padding: 20px 20px;
	z-index: 1;
	position: relative;
	width: 100%;
	line-height: 1;
}

.age-group p {
	text-align:left;
}
.group-event ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.group-event ul li {
	float: left;
	margin-left: 30px;
}
.group-event ul li:first-of-type {
	margin-left: 0;
}
.top-charts {
	text-align:center;
}
.top-charts .inner-wrap {
	width: 100%;
	padding: 20px 20px;
	position: relative;
}
.top-charts h2 {
	color: #666;
	display: block;
	padding: 20px 20px;
	z-index: 1;
	position: relative;
	width: 100%;
	line-height: 1;
}
.top-charts h3 {
	color: #fff;
	display: block;
	z-index: 1;
	position: relative;
	line-height: 1;
	text-align:center;
}
.streakers {
	position: relative;
	display: grid;
	width: 100%;
	padding: 20px;
	align-content: center;
	height: 300px;
	background: url(images/Depositphotos_275705412_L.jpg)no-repeat scroll center center transparent;
}
.streakers::after {
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.3);
	transition:all 0.3s ease-in-out;
}
.streakers:hover::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(220,57,125,0.9);
	transition:all 0.3s ease-in-out;
}
.finishers {
	position: relative;
	display: grid;
	width: 100%;
	padding: 0;
	align-content: center;
	height: 300px;
	background:url(images/Depositphotos_19133677_L.jpg)no-repeat scroll center center transparent;
}
.finishers::after {
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.3);
	transition:all 0.3s ease-in-out;
}
.finishers:hover::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(220,57,125,0.9);
	transition:all 0.3s ease-in-out;
}
.top-charts a.tc-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.top-charts a.tc-link ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #101E4D;
	color: #779BE7;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	text-decoration: none;
	width: 200px;
	display: block;
	z-index: 1;
	margin: 0 auto;
}
.top-charts  a.tc-link:hover ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #779BE7;
	color: #101E4D;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	text-decoration:none;
}
.top-charts  a.tc-link:active ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #779BE7;
	color: #101E4D;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	text-decoration:none;
}
.contact {
	text-align: center;
	background:#282828;
	color:#fff;
}
.contact .inner-wrap {
	padding: 20px 20px;
	position: relative;
	width: 100%;
}
.contact h2 {
	color: #fff;
	display: block;
	padding: 20px 20px;
	z-index: 1;
	position: relative;
	width: 100%;
	line-height: 1;
}
.footer {
	
}
.footer .inner-wrap {
	text-align: center;
	padding: 20px 20px;
	background: #101E4D;
}
.footer-copyright p {
	margin: 0 auto;
	color: #9E9E9E;
}
.footer-copyright a {
	color: #DC397D;
	transition:all 0.3s ease-in-out;
}
.footer-copyright a:hover {
	color:#9E9E9E;
	transition:all 0.3s ease-in-out;
	
}
.footer-copyright a:active {
	color:#9E9E9E;
	
}
}
@media only screen and (min-width:769px) {


.inner-wrap {
	padding: 20px;
	margin: 0 auto;
}


.brushbg-pink, .brushbg-black, .brushbg-gray {
	margin-top: -225px;
}
.separator-1 {
	width: 225px;
	height: 5px;
	margin: 20px auto;
	background: #DB397C;
}
.col-1 {
	width:100%;
	padding:20px;
	box-sizing: border-box;
}
.col-2-1 {
	width:50%;
	padding:20px;
	box-sizing: border-box;
}
.col-2-2 {
	width:50%;
	padding:20px;
	box-sizing: border-box;
}
.row {
	display:flex;
	width:100%;
}
.col-3-1, .col-3-2, .col-3-3 {
	width: 33.33%;
	padding: 20px;
}
.container {
	
}
.header {
	text-align: center;
	display: block;
}
.header .inner-wrap {
	background: url(images/Depositphotos_74611623_L.jpg) no-repeat scroll center center transparent;
	box-shadow: inset 0 0 0 2000px rgba(241,184,209,0.9);
	min-height: 250px;
	padding: 20px 0 20px 0;
	margin-bottom: 50px;
	align-content: center;
	display: grid;
}
.header h1 {
	margin:0 auto;
}
.subtitle h3 span {
	color:#d7387c;
}
.name-search {
	text-align: center;
	background:#dc397d;
	color:fff;
}
.name-search h2 {
	color: #fff;
	display: block;
	padding: 50px 20px;
	z-index: 1;
	position: relative;
	width:490px;
	line-height:1;
}
.name-search .inner-wrap {
	padding: 100px 20px;
	position: relative;
	width:80%;
}
form.rsearch {
	margin: auto;
	max-width: 300px;
	display: flex;
}
form.rsearch input[type="text"] {
	padding: 10px;
	font-size: 17px;
	border: 1px solid #fff;
	float: left;
	width: 80%;
	background: transparent;
	color: #fff;
}
form.rsearch input[type="text"]:focus {
	border: 1px solid #fff;
}

form.rsearch button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #101E4D;
  color: white;
  font-size: 17px;
  border: 1px solid #779BE7;
  border-left: none;
  cursor: pointer;
  transition:all 0.3s ease-in-out;
}

form.rsearch button:hover {
  background: #779BE7;
  border:1px solid #779BE7;
  transition:all 0.3s ease-in-out;
}

form.rsearch::after {
  content: "";
  clear: both;
  display: table;
}
/*.name-search input {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #fff;
	background: transparent;
	color: #fff;
	font-size: 18px;
	font-weight: normal;
}*/
.age-group {
	background:#fff;
	color:#666;
	text-align: center;
}
.age-group .inner-wrap {
	padding:100px 20px;
	position:relative;
	width:80%;
}
.age-group .row {
	padding: 50px 20px;
}
.age-group h2 {
	color: #666;
	display: block;
	padding: 50px 20px;
	z-index: 1;
	position: relative;
	width: 490px;
	line-height: 1;
}

.age-group p {
	text-align:left;
}
.group-event ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.group-event ul li {
	float: left;
	margin-left: 30px;
}
.group-event ul li:first-of-type {
	margin-left: 0;
}
.top-charts {
	text-align:center;
}
.top-charts .inner-wrap {
	width: 80%;
	padding: 100px 20px;
	position: relative;
}.top-charts h2 {
	color: #666;
	display: block;
	padding: 50px 20px;
	z-index: 1;
	position: relative;
	width: 490px;
	line-height: 1;
}
.top-charts h3 {
	color: #fff;
	display: block;
	z-index: 1;
	position: relative;
	line-height: 1;
	text-align:center;
}
.streakers {
	position: relative;
	display: grid;
	width: 100%;
	padding: 0;
	align-content: center;
	height: 700px;
	background:url(images/Depositphotos_275705412_L.jpg)no-repeat scroll center center transparent;
}
.streakers::after {
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.3);
	transition:all 0.3s ease-in-out;
}
.streakers:hover::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(220,57,125,0.9);
	transition:all 0.3s ease-in-out;
}
.finishers {
	position: relative;
	display: grid;
	width: 100%;
	padding: 0;
	align-content: center;
	height: 700px;
	background:url(images/Depositphotos_19133677_L.jpg)no-repeat scroll center center transparent;
}
.finishers::after {
	content:'';
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.3);
	transition:all 0.3s ease-in-out;
}
.finishers:hover::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(220,57,125,0.9);
	transition:all 0.3s ease-in-out;
}
.top-charts a.tc-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.top-charts a.tc-link ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #101E4D;
	color: #779BE7;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	text-decoration: none;
	width: 200px;
	display: block;
	z-index: 1;
	margin: 0 auto;
}
.top-charts  a.tc-link:hover ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #779BE7;
	color: #101E4D;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	text-decoration:none;
}
.top-charts  a.tc-link:active ~ a.tc-btn {
	font-size: 16px;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	padding: 15px 50px;
	background: #779BE7;
	color: #101E4D;
	border: 1px solid #779BE7;
	transition: all 0.3s ease-in-out;
	text-decoration:none;
}
.contact {
	text-align: center;
	background:#282828;
	color:#fff;
}
.contact .inner-wrap {
	padding:100px 20px;
	position:relative;
	width:80%;
}
.contact h2 {
	color: #fff;
	display: block;
	padding: 50px 20px;
	z-index: 1;
	position: relative;
	width:490px;
	line-height:1;
}
.footer {
	
}
.footer .inner-wrap {
	text-align: center;
	padding: 50px 20px;
	background: #101E4D;
}
.footer-copyright p {
	margin: 0 auto;
	color: #9E9E9E;
}
.footer-copyright a {
	color: #DC397D;
	transition:all 0.3s ease-in-out;
}
.footer-copyright a:hover {
	color:#9E9E9E;
	transition:all 0.3s ease-in-out;
	
}
.footer-copyright a:active {
	color:#9E9E9E;
	
}
}